import { DestroyRef, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { AbilityBuilder, createMongoAbility, MongoQuery } from '@casl/ability';

import { UserService } from '@ppg/auth';
import { Permission } from '@ppg/core/enums';
import { Actions, AppAbility, Subjects, UserInfo } from '@ppg/core/models';

import { getPermission } from './utils/permission.utils';

@Injectable({ providedIn: 'root' })
export class RoleService {
  private readonly userService = inject(UserService);
  private readonly destroyRef = inject(DestroyRef);

  readonly #ability = signal<AppAbility>(inject(createMongoAbility), {
    // Convert ability to signal based, each time we emit the same ability object,
    // to make it usable inside effect/computed we use workaround with equal false
    equal: () => false,
  });

  readonly ability = this.#ability.asReadonly();

  constructor() {
    this.destroyRef.onDestroy(this.ability().on('updated', () => this.#ability.set(this.#ability())));

    effect(() => {
      const user = this.userService.user();
      untracked(() => this.updateAbilityForUser(user));
    });
  }

  checkPermission(required: Permission): boolean {
    const user = this.userService.user();
    if (!user) {
      return false;
    }

    const requiredAbilities = this.mapPermissionToAbility(required, user);
    if (!requiredAbilities) {
      return false;
    }

    const actualAbility = this.ability();

    return requiredAbilities.every(({ action, subject }) => actualAbility.can(action, subject));
  }

  checkPermissions(required: Permission[]): boolean {
    return required.every((p) => this.checkPermission(p));
  }

  private updateAbilityForUser(user: UserInfo | null): void {
    const { can, rules } = new AbilityBuilder<AppAbility>(createMongoAbility);

    if (!user) {
      this.ability().update(rules);
      return;
    }

    user.permissions.forEach((permission: Permission) => {
      const abilities = this.mapPermissionToAbility(permission, user);
      if (abilities) {
        abilities.forEach((ability) => {
          if (ability.condition) {
            can(ability.action, ability.subject, ability.condition as MongoQuery<never>);
          } else {
            can(ability.action, ability.subject);
          }
        });
      }
    });

    this.ability().update(rules);
  }

  private mapPermissionToAbility(
    permission: Permission,
    user: UserInfo,
  ): { action: Actions; subject: Subjects; condition?: MongoQuery }[] | null {
    return getPermission(permission, user);
  }
}
